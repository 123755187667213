.form-checkbox {
  &__field {
    font-family: "Libre Franklin";
    font-size: 14px;
    letter-spacing: 0;
    text-align: left;
    color: black;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d70e7f;
}

/** focus shadow **/
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 #d70e7f;
  border-color: #d70e7f;
}
.custom-control-label::before {
  border-color: #d70e7f !important;
}
.custom-checkbox {
  margin-top: 1rem;
  vertical-align: bottom;
}
.custom-control-label {
  // line-height: normal;
  top: -23px;
  padding-left: 10px;
}
.custom-control-label::before,
.custom-control-label::after {
  width: 1.25rem !important;
  height: 1.25rem !important;
}
$color-green: #d70e7f;
.checkbox_pics {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
  font-family: "Libre Franklin";
  label {
    position: relative;
    min-height: 34px;
    display: block;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      margin: 4px;
      width: 20px;
      height: 20px;
      transition: transform 0.28s ease;
      border-radius: 3px;
      border: 2px solid $color-green;
    }
    &:after {
      content: "";
      display: block;
      width: 9px;
      height: 5px;
      border-bottom: 2px solid $color-green;
      border-left: 2px solid $color-green;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
    &:checked ~ label {
      &:before {
        border: 2px solid $color-green;
      }
      &:after {
        transform: rotate(-45deg) scale(1);
      }
    }
    &:focus + label::before {
      outline: 0;
    }
  }
}
