// MEDIA QUERY MANAGER
/*
0 - 576px:      Phone
576 - 992px:    Tablet portrait
992 - 1200px:   Tablet landscape
[1200 - 1660] is where our normal styles apply
1660px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

*/
@mixin respond($breakpoint) {
  @if $breakpoint == phoneSmall {
    @media only screen and (max-width: 340px) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 992px) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media only screen and (max-width: 1365px) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 1660px) {
      @content;
    }
  }
}
