.app-button {
  color: #d70e7f;
  fill: #d70e7f;

  background-color: white;
  height: 45px;
  width: 200px;
  padding: 10px 20px;
  font-family: "Libre Franklin";
  font-size: 16px;
  border: 1px solid #d70e7f;
  border-radius: 1000px;
  cursor: pointer;

  &:hover {
    color: white;
    fill: white;

    background-color: #d70e7f;
  }
}

.btn-retourn-to-home {
  text-align: center;
  margin-top: 25px;
}

.app-button-disable {
  color: #d70e7f;
  fill: #d70e7f;

  background-color: white;
  height: 45px;
  width: 200px;
  padding: 10px 20px;
  font-family: "Libre Franklin";
  font-size: 16px;
  border: 1px solid #d70e7f;
  border-radius: 1000px;
}

.app-button-loading, .app-button-loading:hover {
  position:relative;
  background-color: white;
  color: #d70e7f;
  opacity: 65%;
}

.app-button-loading:before {
  animation: loading 1s linear infinite;
  @keyframes loading {
    0% { 
      transform: rotate(0); 
    }
    100% { 
      transform: rotate(360deg);
    }
  }
  content: " ";
  display: block;
  position:absolute;
  left: 5px;
  top: 15px;
  border-top: #d70e7f solid 1px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  
}

.no-style-button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;

  &:hover {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  }

  &:active {
    outline: none;
    border: none;
  }

  button:focus {
    outline: 0;
  }
}

.app-form-button {
  color: #d70e7f;
  fill: #d70e7f;

  background-color: white;
  font-family: "Libre Franklin";
  font-size: 14px;
  border: 1px solid #d70e7f;
  border-radius: 1000px;
  padding: 5px 10px;

  cursor: pointer;

  &:hover {
    color: white;
    fill: white;

    background-color: #d70e7f;
  }
}

.cancel-button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  fill: red;
  float: right;

  &:hover {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    cursor: pointer;
  }

  &:active {
    outline: none;
    border: none;
  }

  button:focus {
    outline: 0;
  }
}
.button-modal {
  color: white;
  fill: white;

  background-color: #d70e7f;
  height: 40px;
  width: 200px;
  padding: 10px 20px;
  font-family: "Libre Franklin";
  font-size: 14px;
  border: 1px solid #d70e7f;
  border-radius: 10px;
  cursor: pointer;
}
.add-button {
  padding-bottom: 20px;
  display: inline;
  &__label {
    color: black;
    vertical-align: middle;
    margin-left: 5px;
  }
  &__round {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #d70e7f;
    border-radius: 50%;
    border: 1px solid #d70e7f;
    height: 45px;
    width: 45px;
    cursor: pointer;
    display: table-cell;
    &:hover {
      color: #d70e7f;
      fill: #d70e7f;
      background-color: white;
    }
  }
}
.button-upload {
  background-color: #d70e7f !important;
  &:hover {
    color: #d70e7f !important;
    fill: #d70e7f !important;
    background-color: white !important;
    border: 1px solid #d70e7f !important ;
  }
}
