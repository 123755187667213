.app-modal {

  &--description {
    .modal-content {
      padding: 1rem 2rem;
      border-radius: 50px !important;
      
    }
  }
}
