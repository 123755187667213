.tooltip-info {
  background-color: #d70e7f;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
}
.drug_tooltip {
  margin-left: -20px;
}
.popover {
  border-color: #d70e7f !important;
}
.popover-body {
  color: #d70e7f !important;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #d70e7f !important;
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #d70e7f !important;
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #d70e7f !important;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #d70e7f !important;
}
.tooltip-card {
  position: absolute;
  top: -10px;
  left: -10px;
}

.tooltip-pharmaco-date,
.tooltip-drug-end,
.tooltip-background-date,
.tooltip-posologie {
  position: absolute;
  top: 20%;
  right: -7%;
}

.tooltip-pharmaco-descr {
  position: absolute;
  top: 4%;
  right: 7%;
  @include respond(phone) {
    position: absolute;
    top: 5%;
    right: 0%;
  }
}
.tooltip-date {
  position: absolute;
  right: 7%;
  @include respond(phone) {
    position: absolute;
    right: 0%;
  }
}
.tooltip-checkBox {
  position: absolute;
  top: -15%;
  right: -25%;
}
