body {
  overflow-x: hidden;
  padding-bottom: 10px;
}

.home-container {
  margin-right: 15px !important;
  margin-left: 15px !important;
  margin-top: 3rem !important;
  @include respond(desktop) {
    margin-right: 15px !important;
    margin-left: 15px !important;
    margin-top: 2rem !important;
  }

  @include respond(big-desktop) {
    margin-right: 15px !important;
    margin-left: 15px !important;
    margin-top: 5.2rem !important;
  }
}
