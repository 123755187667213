.form-block {
  &__flex-item {
    padding: 15px 30px;

    @include respond(phone) {
      margin-right: 0px;
    }
  }

  &__label {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
