.form-card {
  position: relative;
  border-radius: 15px;
  cursor: pointer;
  height: 200px;
  width: 300px;
  box-shadow: 0px 0px 100px 1px lightgray;
  padding: 5%;
  background-color: white;
  fill: #e00d80;
  color: #484848;

  @include respond(desktop) {
    height: 200px;
    width: 280px;
  }

  @include respond(tab-land) {
    height: 200px;
    width: 280px;
  }

  @include respond(tab-port) {
    height: 180px;
    width: 280px;
  }

  @include respond(sm) {
    height: 180px;
    width: 280px;
  }

  @include respond(phone) {
    height: 180px;
    width: 280px;
  }

  @include respond(phoneSmall) {
    height: 180px;
    width: 280px;
  }

  &:hover,
  &:visited,
  &:active {
    background-color: #e00d80;
    fill: white;
    color: white;
  }

  &--selected {
    background-color: #e00d80;
    fill: white;
    color: white;
  }

  &__icon {
    svg {
      width: 28%;
      margin: 0 auto;

      @include respond(desktop) {
        width: 26%;
      }

      @include respond(tab-land) {
        width: 26%;
      }

      @include respond(tab-port) {
        width: 26%;
      }

      @include respond(sm) {
        width: 26%;
      }

      @include respond(phone) {
        width: 26%;
      }

      @include respond(phoneSmall) {
        width: 26%;
      }
    }
  }

  &__text {
    font-family: "Libre Franklin";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;

    @include respond(desktop) {
      font-size: 20px;
      line-height: 20px;
    }

    @include respond(tab-land) {
      font-size: 20px;
      line-height: 20px;
    }

    @include respond(tab-port) {
      font-size: 18px;
      line-height: 18px;
    }

    @include respond(sm) {
      font-size: 16px;
      line-height: 16px;
    }

    @include respond(phone) {
      font-size: 15px;
      line-height: 15px;
    }

    @include respond(phoneSmall) {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
