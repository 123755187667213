.contact-form {
  &__field {
    height: 40px;
    width: 100%;
    margin: 0px 0px 10px 0px;

    padding-left: 15px;
    border: 1px solid #979797;
    border-radius: 4px;
    font-family: "Libre Franklin";
    font-size: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #334559;
  }
  &__label {
    margin-bottom: 0px;
    font-family: "Libre Franklin";
    font-size: 16px;
    color: #334559;
  }
}
