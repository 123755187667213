.stepper-item-inner-active {
  background-color: #d70e7f !important;
  cursor: default;
}
.stepper-item-outer {
  border-color: #d70e7f !important;
  cursor: default;
}
.stepper-item-inner-completed {
  background-color: #d70e7f !important;
  cursor: default;
}
