.radio-inline input[type="radio"] {
  -moz-appearance: initial;
  visibility: hidden;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 5px;
  padding: 0;
}
.radio-inline input[type="radio"]:before {
  position: relative;
  margin: 4px -25px -4px 0;
  display: inline-block;
  visibility: visible;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #d70e7f;
  background: #fff;
  content: "";
}
.radio-inline input[type="radio"]:checked:after {
  position: relative;
  top: 0;
  left: 9px;
  display: inline-block;
  visibility: visible;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  background: #d70e7f;
  content: "";
}
.radio-inline input[type="radio"].false:checked:after {
  background: blue;
}
.radio-inline label {
  display: inline-block;
  height: 25px;
  line-height: 25px;
}
