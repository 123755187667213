.app-title {
  color: #01438f;
  font-family: "Libre Franklin";
  font-size: 34px;
  text-align: left;
}

.app-subtitle {
  color: #494949;
  font-family: "Libre Franklin";
  font-size: 24px;
  text-align: left;
}

.question-label {
  color: #01438f;
  font-family: "Libre Franklin";
  font-size: 24px;
  text-align: left;

  @include respond(phone) {
    font-size: 20px;
  }
}

.form-label {
  color: #494949;
  font-family: "Libre Franklin";
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  text-align: left;
}

.mandatory-field {
  color: red;
  font-family: "Libre Franklin";
  font-size: 14px;
  margin: 0;
  text-align: left;
  display: block;
  margin-bottom: 10px;
  margin-top: -10px;
}

